import React, {useEffect, useState} from "react";
import {IoMdArrowDropdown, IoMdArrowDropup} from "react-icons/io";

const FilterAndSort = ({
                         setFilteredPurchaseCards,
                         purchasedVouchers,
                         selectedFilter,
                         setSelectedFilter,
                         allVouchers,
                         allCategories,
                         setFilteredCard
                       }) => {
  const [filterDropDown, setFilterDropDown] = useState(false);
  const filterOptions = allCategories?.filter((v, i, a) => a.indexOf(v) === i);
  const routeName = window.location.pathname;

  // for set products according to selected filter option
  useEffect(() => {
    if (routeName === '/shop') {
      const cardForSelectedFilter = [];
      allVouchers.sort(function (a, b) {
        return a.tokenId - b.tokenId
      });
      if (selectedFilter === 'All Categories') {
        setFilteredCard(allVouchers)
        return
      }
      for (let i = 0; i < allVouchers.length; i++) {
        if (selectedFilter === allVouchers[i].category) {
          cardForSelectedFilter.push(allVouchers[i])
        }
      }
      setFilteredCard(cardForSelectedFilter)
    }

    if (routeName === '/wallet') {
      const filteredPurchasedVouchers = [];
      purchasedVouchers.sort(function (a, b) {
        return a.tokenId - b.tokenId
      });
      if (selectedFilter === 'All Categories') {
        setFilteredPurchaseCards(purchasedVouchers)
        return
      }
      for (let i = 0; i < purchasedVouchers.length; i++) {
        if (selectedFilter === purchasedVouchers[i].category) {
          filteredPurchasedVouchers.push(purchasedVouchers[i])
        }
      }
      setFilteredPurchaseCards(filteredPurchasedVouchers)
    }
  }, [selectedFilter, allVouchers, purchasedVouchers]);

  return (
    <>
      <div className='dropdown_menu_div'>

        <div className='sort_filter_div'>
          <p className='filter_text'>filter</p>
          <div className="dropdown">
            <button onClick={() => setFilterDropDown(!filterDropDown)}
                    className={`dropbtn ${filterDropDown && 'borderClass'}`}>{selectedFilter}{filterDropDown ?
              <IoMdArrowDropup className='drop_btn'/> :
              <IoMdArrowDropdown className='drop_btn'/>}</button>
            <div className={`dropdown-content ${filterDropDown && 'trueDropDown'}`}>
              <ul>
                {filterOptions?.map((item, i) => item !== selectedFilter && (
                  <li key={i} onClick={() => {
                    setSelectedFilter(item);
                    setFilterDropDown(false);
                  }
                  }>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default FilterAndSort;