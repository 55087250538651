import React from "react";
import './loader.scss';
import loader from '../../assets/images/loader.gif'

const Loader = ({loading,loadingMessage}) => {

  return (
    <>
      <div className={`loading_div ${(loading) && 'loading_div_display'}`}>
        {loadingMessage && <p>Please do not go back or close this tab.</p>}
        <img className='loading_img' src={loader} alt='loading..'/>
      </div>
    </>
  )
}
export default Loader;