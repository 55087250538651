import React from "react";
import { MdOutlineErrorOutline } from "react-icons/md";
import './connect.scss';
import Loader from "../loader/Loader";


const Connect = ({ status, connect }) => {

  const connectWallet = () => {
    connect();
  };
  return (
    <>
      {status === "initializing" || status === "connecting" ?
        <><Loader loading={true}/></> :

        <div className='main__div'>
          {status === "connected"
            ? <></>
            : <button
              onClick={
                status === "notConnected"
                  ? connectWallet
                  : null
              }
            >
              {status === "unavailable" ?
                <><MdOutlineErrorOutline/> Install MetaMask</>
                : null}

              {status === "notConnected" ?
                <>Connect</>
                : null}
            </button>
          }
        </div>
      }
    </>
  )
}
export default Connect;