import React, {useEffect, useState} from "react";

const WhiteListing = ({
                        selectedCard,
                        setSelectedCard,
                        handleMouseLeave,
                        handleHovered,
                        purchasedVouchers,
                        handleScroll,
                        isScrolling,
                        handleWalletSubmit
                      }) => {

  const [whiteListVouchers, setWhiteListVouchers] = useState([]);

  const handleSelect = (item) => {
    if (selectedCard === item) {
      setSelectedCard()
    } else {
      setSelectedCard(item);
    }
  };

  useEffect(() => {
    const allWhiteListingCards = []
    purchasedVouchers?.map((voucher) => {
      if (voucher.category === 'Whitelist') {
        allWhiteListingCards.push(voucher)
      }
    })
    setWhiteListVouchers(allWhiteListingCards)
  }, [purchasedVouchers]);

  return (
    <>
      <div className='center_div '>
        {whiteListVouchers.length ?
          <div className={`scroll_div ${isScrolling && 'scrolling'}`} onScroll={handleScroll}>
            {whiteListVouchers.map((item, i) => (
              <div key={i}
                   className={`card_display_div ${selectedCard?.tokenId === item.tokenId && 'selected_class'}`}
                   onMouseLeave={handleMouseLeave}
                   onMouseEnter={() => handleHovered(item)}
                   onClick={() => handleSelect(item)}>
                <img src={item.image} alt='img'/>
                <div className='voucher_details'>
                  <p className='voucher_heading'>{item.title}</p>
                  <div className='card_bottom_div'>
                    <p className='id_category'><span>ID: </span> {item.tokenId}</p>
                    <p className='id_category'><span>Category: </span> {item.category}</p>
                  </div>
                </div>
                <div className='wl_submit_div'>
                  <button className='wl_submit' onClick={(e) => handleWalletSubmit(e, item)}>submit</button>
                </div>
              </div>
            ))}
          </div> :
          <div className='navigate_div'>You don’t have any whitelist spots.</div>
        }
      </div>
    </>
  )
}
export default WhiteListing;