import React, {useEffect} from "react";
import CartItem from "../../../components/CartItem/CartItem";
import RouteChange from "../../../components/RouteChange";

const RightColumn = ({
                      handleRemoveAll,
                      handleRemove,
                      cart,
                      commaSeparateNumber,
                      totalPrice,
                      setOpenBuyModal,
                      flag,
                      handleCheckApproval,
                      isApproved,
                      handleSetApproval,
                      account,
                      balance,
                    }) => {

  useEffect(() => {
    handleCheckApproval()
  }, [account,totalPrice])

  return (
    <>
      <div className='right_div'>
        <RouteChange/>
        <div className='cart_div'>
          <div className='cart_heading_div'>
            <h1>cart</h1>
            {cart.length !== 0 &&
            <div className='cart_edit_div'>
              <p className='View_cart' onClick={() => cart.length !== 0 && setOpenBuyModal(true)}>View cart</p>
              <p className='clear_cart' onClick={handleRemoveAll}>Clear cart</p>
            </div>}
          </div>
          {cart.length > 0 ?
            <>
              <div className='cart_items_div'>
                <CartItem cart={cart} handleRemove={handleRemove} flag={flag}/>
              </div>
              <div className='pump_total_div'>
                <span className='total'>Total</span>
                <span className='pump_value'>{commaSeparateNumber(totalPrice)} $PUMP</span>
              </div>
            </> :
            <div className='empty_cart_div'>
              No items.
            </div>
          }
          <div className='checkout_div'>
            {
              isApproved ?
                <button className='checkout_button'
                        onClick={handleSetApproval}>approve
                </button> :
                <button disabled={cart.length === 0 || balance < totalPrice} className='checkout_button'
                        onClick={() => setOpenBuyModal(true)}>checkout
                </button>
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default RightColumn;