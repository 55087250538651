import React, {useEffect, useRef, useState} from "react";
import {RiCloseCircleFill} from "react-icons/ri";
import {CSVLink} from "react-csv";
import {getListOfRaffleWinners} from "../../../utils/backendApi";

const RaffleModal = ({
                       modalRef,
                       raffleModalOpen,
                       handleExitFromRaffleModal,
                       handleOpenConfirmationModal,
                       selectedRaffleVoucher,
                       handleGetTicketAmount,
                       raffleOwnersList,
                       setLoading,
                       ticketAmount
                     }) => {
  const [totalSold, setTotalSold] = useState(0);
  const [pickedWinners, setPickedWinners] = useState([]);
  const csvLink = useRef()
  const headers = [
    { label: "owner Address", key: "owner" },
    { label: "Discord Name", key: "discordId" },
    { label: "Total Amount", key: "count" }
  ];

  useEffect(() => {
    let sum = 0;
    raffleOwnersList?.map((owner) => {
      sum += owner.count
    })
    setTotalSold(sum)
  }, [raffleOwnersList])

  const handlePickAndDownload = async () => {
    setLoading(true)
    const getPickWinners = await getListOfRaffleWinners(ticketAmount, selectedRaffleVoucher.tokenId)
    await setPickedWinners(getPickWinners)
    setTimeout(() => {
      csvLink.current.link.click()
      setLoading(false)
    }, 500)
  }

  return (
    <>
      <div ref={modalRef} className={`popup_main_div ${raffleModalOpen && 'display'}`}>
        <div className='popup_div popup_raffle_div'>
          <span className='close_icon'><RiCloseCircleFill onClick={() => {
            handleExitFromRaffleModal();
            setPickedWinners([])
          }}/></span>
          <div className='voucher_details_div'>
            <p className='voucher_name'>{selectedRaffleVoucher?.title}</p>
            <p className='text_heading'>Raffle</p>
          </div>
          <div className='tickets_detail_div'>
            <p className='sold_tickets'>{totalSold} tickets sold so far</p>
            <p className='owners'>to {raffleOwnersList?.length} holders</p>
            <p className='label'>Number of winners to pick</p>
            <div className='Ticket_input_div'>
              <input spellCheck='false' id='tickets' type='number'
                     value={ticketAmount}
                     autoComplete="off"
                     placeholder=" " onChange={(e) => handleGetTicketAmount(e)}/>
            </div>
          </div>
          <div className='popup_btn_div'>
            <button onClick={handlePickAndDownload} className='popup_button pick'
                    disabled={!ticketAmount || ticketAmount > raffleOwnersList.length}>pick & Download
            </button>
            <CSVLink
              ref={csvLink}
              filename={`${selectedRaffleVoucher?.title} - winners.csv`}
              data={pickedWinners}
              headers={headers}/>
          </div>
          <div className='download_tickets_div'>
            <h3 className={`${raffleOwnersList.length === 0 && 'disabled'}`} ><CSVLink
              filename={`${selectedRaffleVoucher?.title} - all.csv`}
              data={raffleOwnersList}
              disabled={raffleOwnersList.length === 0}
              headers={headers}>Download All</CSVLink></h3>
            <h3 className='close_raffle' onClick={handleOpenConfirmationModal}>Close Raffle</h3>
          </div>
        </div>
      </div>
    </>
  )
}
export default RaffleModal;