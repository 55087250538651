import React, {useEffect, useState} from "react";
import InfoPanel from "../../../components/InfoPanel";

const LeftColumn = ({
                      selectedUtilityOption,
                      handleUtilityChange,
                      allConfigs,
                      selectedGuzzler,
                      metaData,
                      isFetching,
                      hoveredCard
                    }) => {
  const [configDetails, setConfigDetails] = useState([]);

  useEffect(() => {
    if (allConfigs) {
      const allDetals = Object.keys(allConfigs?.gasBackTokens).map((key) => {
        return {
          tier: allConfigs?.gasBackTokens[key].tokenId,
          min: allConfigs.gasBackTokens[key].min,
          max: allConfigs.gasBackTokens[key].max
        }
      });
      setConfigDetails(allDetals)
    }
  }, [allConfigs]);

  return (
    <>
      <div className='left_div'>

        <div className='radio_div'>
          <div className={`radio_option_div ${selectedUtilityOption === 'gas_back' && 'active'}`}
               onClick={(e) => handleUtilityChange("gas_back")}>
            <span className={`${selectedUtilityOption === 'gas_back' ? 'line' : 'light'}`}></span>
            <span>Gas Refund</span>
          </div>
          <div className={`radio_option_div ${selectedUtilityOption === 're_roll' && 'active'}`}
               onClick={(e) => handleUtilityChange('re_roll')}>
            <span className={`${selectedUtilityOption === 're_roll' ? 'line' : 'light'}`}></span>
            <span>Trait Reroll</span>
          </div>
          <div className={`radio_option_div ${selectedUtilityOption === 'whitelisting' && 'active'}`}
               onClick={(e) => handleUtilityChange('whitelisting')}>
            <span className={`${selectedUtilityOption === 'whitelisting' ? 'line' : 'light'}`}></span>
            <span>Whitelisting</span>
          </div>
        </div>

        {selectedUtilityOption === 'whitelisting' ?
          <InfoPanel selectedCard={hoveredCard}/> :
          <div className='card_info_div'>
            <div className='info_div'>
              <h3>info</h3>
              {selectedUtilityOption === 're_roll' &&
              <p className='card_id'>ID: <span>{selectedGuzzler ? selectedGuzzler : ""}</span></p>}
            </div>
            <div className='info_scroll_div'>

              {selectedUtilityOption === 'gas_back' &&
              configDetails?.map((item, index) => (
                <div key={index} className='minmax_details_div'>
                  <p><span>Tier {item.tier}: </span> {item.min} - {item.max} ETH</p>
                </div>
              ))}

              {selectedUtilityOption === 're_roll' && isFetching &&
              <div className='loading'>loading</div>}

              {selectedUtilityOption === 're_roll' && !isFetching && metaData?.attributes?.length && selectedGuzzler !== undefined &&
              <>
                <p className='info_text'>Current traits of the token</p>
                <div className='scroll_div'>
                  {metaData.attributes.map((data, i) => (
                    <div key={i} className='minmax_details_div re_roll_details'>
                      <p><span>{data.trait_type}: </span> {data.value}</p>
                    </div>
                  ))}
                </div>
              </>
              }

              {selectedUtilityOption === 're_roll' && !isFetching && selectedGuzzler === undefined &&
              <div className='select_token_div'>Select a Guzzler to view it's traits.</div>
              }

            </div>
          </div>
        }

      </div>
    </>
  )
}
export default LeftColumn;