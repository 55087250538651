import React from "react";
import './cartItem.scss';
import {ImCross} from "react-icons/im";

const CartItem = ({ cart, handleRemove, flag }) => {

  return (
    <>
      {cart?.map((cartItem, i) => (
        <div
          className={`single_item ${cartItem.tokenId === flag && 'animate_class'} ${flag === 'deleteAll' && 'animate_class'}`}
          key={i}>
          <div className='remove' onClick={() => handleRemove(cartItem.tokenId)}><ImCross/></div>
          <div className='details_div'>
            <div className='img_div'>
              <img src={cartItem.image} alt='img'/>
            </div>
            <div className='item_details'>
              <p
                className='card_heading'>{cartItem.title}</p>
              <p>ID: {cartItem.tokenId}</p>
            </div>
          </div>
          <div className='price_qty_div'>
            <h3>{cartItem.price} $PUMP</h3>
            <p>Qty: {cartItem.Qty}</p>
          </div>
        </div>
      ))}
    </>
  )
}
export default CartItem;