import React from "react";

const CloseRaffleModal = ({closeRaffleModalOpen,modalRef,handleDeleteAllRaffles ,handleCloseConfirmationModal}) => {

  return (
    <>
      <div ref={modalRef} className={`popup_main_div ${closeRaffleModalOpen && 'display'}`}>
        <div className='popup_div popup_Confirm_div'>
          <div className='confirmation_text'>
            <p className='fist_line'>Closing the raffle will burn all the raffle tickets.</p>
            <p>Would you like to continue?</p>
          </div>
          <div className='popup_btn_div'>
            <button className='popup_button confirm' onClick={handleDeleteAllRaffles} >yes</button>
            <button className='popup_button' onClick={handleCloseConfirmationModal} >No</button>
          </div>

        </div>
      </div>
    </>
  )
}
export default CloseRaffleModal;