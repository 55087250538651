import React from "react";
import './tweetModal.scss';
import {RiCloseCircleFill} from "react-icons/ri";
import {BsTwitter} from "react-icons/bs";
import configs from '../../config/test_config.json';

const TweetModal = ({ postTweet, modalRef, handleCloseTweetModal }) => {

  const tweetMessages = {
    purchase: `I just purchased some exciting items from @GasGuzzlers_NFT marketplace.`,
    refund: `I just refunded ${postTweet?.refundedAmount} amount of ETH using a Gas Voucher from @GasGuzzlers_NFT marketplace! Free ETH airdropped into my wallet!`,
    reroll: `I just changed my Guzzler %23${postTweet?.guzzlerId}'s ${postTweet?.traitType} trait from ${postTweet?.currentTrait} to ${postTweet?.offeredTrait} using @GasGuzzler_NFTs trait re-roll from the GG marketplace!`,
    whitelist: `I just won a ${postTweet?.wlSpot} Whitelist Spot from @GasGuzzlers_NFT Marketplace! Join the community today!`
  }

  return (
    <>
      <div ref={modalRef} className={`popup_main_div ${postTweet.isSuccessful && 'display'}`}>
        <div className='popup_div tweet_popup'>
          <span className='close_icon'><RiCloseCircleFill onClick={handleCloseTweetModal}/></span>
          <p className='heading_tweet'>{configs.transactionMessages[postTweet.transactionType]}</p>
          <div className='div_for_tweet_btn'>
            <button className='tweet_button'>
              <a className="twitter-share-button"
                 target='_blank'
                 text='Share this tweet'
                 data-size="large"
                 href={`https://twitter.com/intent/tweet?text=${tweetMessages[postTweet.transactionType]}`}>
                <BsTwitter/></a>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default TweetModal;