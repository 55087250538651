import React from "react";
import './header.scss';
import {FaDiscord} from "react-icons/fa";
import 'tippy.js/dist/tippy.css';
import Tippy from "@tippyjs/react";

const Header = ({ balance, account, status, disCordMessage }) => {

  return (
    <div className='head_div'>
      <div className='main_header'>
        {status === 'connected' &&
        <div className='heading_div'>
          <p className='heading_value'>
            {account
              ? account.slice(0, 9) + "..."
              : "..."}
          </p>
          <p className='heading_value'>
            <Tippy placement="bottom" arrow={true}  content={disCordMessage?.isDiscord ? disCordMessage?.message.length < 20 ?disCordMessage?.message : `${disCordMessage?.message.slice(0,17)}...`:disCordMessage?.message } >
              <span><FaDiscord className={`${!disCordMessage?.isDiscord && "notDiscord"}`}/></span>
            </Tippy>
          </p>
        </div>}
        <div className='logo_div'>
          <h1 className='logo'>Gas guzzlers</h1>
        </div>
        {status === 'connected' && <div className='heading_div'>
          <p className='heading_value'>{Number(balance).toFixed(2)} $PUMP</p>
        </div>}
      </div>
    </div>
  )
}

export default Header;