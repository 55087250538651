import axios from "axios";
import config from '../config/test_config.json';

export const getAllProducts = async () => {
  try {
    const { data } = await axios.get(config.BASE_URL + 'item');
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const buyProducts = async (dataParams) => {
  try {
    const { data } = await axios.post(config.BASE_URL + config.end_points.buyProducts, dataParams);
    return data;
  } catch (error) {
    console.error("Backend-error:" + error);
    return false;
  }
};

export const claimTokens = async (dataParams) => {
  try {
    const { data } = await axios.post(config.BASE_URL + config.end_points.claimTokens, dataParams);
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const getConfigData = async () => {
  try {
    const { data } = await axios.get(config.BASE_URL + config.end_points.gasBackTokens);
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const getTransactionValue = async (txHash) => {
  try {
    const { data } = await axios.get(config.BASE_URL + config.end_points.gettxHashResponse + txHash);
    return data?.totalGasPrice;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const getTokens = async (userAddress) => {
  try {
    const { data } = await axios.get(config.BASE_URL + config.end_points.getTokens + userAddress);
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const getTraitReRolls = async (dataParams) => {
  try {
    const { data } = await axios.post(config.BASE_URL + config.end_points.getRe_roll, dataParams);
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const setTraits = async (dataParams) => {
  try {
    const { status } = await axios.post(config.BASE_URL + config.end_points.setTraits, dataParams);
    return status;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const getOldValue = async (tokenId) => {
  try {
    const { data } = await axios.get(config.TRAIT_BASE_URL + config.end_points.getOldValue + tokenId);
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const checkEligibility = async (tokenId) => {
  try {
    const { status } = await axios.get(config.TRAIT_BASE_URL + config.end_points.checkIsValid + tokenId);
    return status;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const checkDiscordUserName = async (userAddress) => {
  try {
    const { data } = await axios.get(config.DISCORD_CHECK_URL + userAddress);
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const burnWhitelistToken = async (dataParams) => {
  try {
    const { data } = await axios.post(config.BASE_URL + config.end_points.whitelistBurner, dataParams);
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const getListOfRaffleOwners = async (tokenId) => {
  try {
    const { data } = await axios.get(config.BASE_URL + config.end_points.getRaffleDetails + tokenId);
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const getListOfRaffleWinners = async (numberOfWinners, tokenId) => {
  try {
    const { data } = await axios.get(config.BASE_URL + config.end_points.getRaffleWinners + tokenId + '/' + numberOfWinners);
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const deleteRaffle = async (dataParams) => {
  try {
    const { data } = await axios.delete(config.BASE_URL + config.end_points.deleteRaffle, { data: dataParams });
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};

export const placeBid = async (dataParams) => {
  try {
    const { data } = await axios.post(config.BASE_URL + config.end_points.PlaceBid, dataParams);
    return data;
  } catch (error) {
    console.error("Backend-error: " + error);
    return false;
  }
};
