import React from "react";
import {FaClipboardList} from "react-icons/fa";

const CenterColumn = ({
                        selectedCard,
                        setSelectedCard,
                        cart,
                        setCart,
                        filteredCard,
                        handleShowWalletList,
                        handleHovered,
                        handleMouseLeave,
                        handleScroll,
                        isScrolling,
                        isAdmin,
                        handleBidOpen,
                        handleOpenRaffleModal
                      }) => {
  //for select card and view it's details in info panel
  const handleSelect = (item) => {
    if (selectedCard === item) {
      setSelectedCard()
    } else {
      setSelectedCard(item);
    }
  };


  //add product in cart
  const handleAddCart = async (newItem, e) => {
    e.stopPropagation();
    if (newItem.totalRemaining > 0 && newItem.remainingToken > 0) {
      const addNewQty = { ...newItem, Qty: 1 }
      setCart([...cart, addNewQty])
    }
  };

  // for check item in cart or not to show counter and add to cart button alternate
  const HandleCheckInCart = ({ item }) => {
    const isExistItem = cart.find((elem) => elem.tokenId === item.tokenId)

    if (isExistItem) {
      return <div className='counter_div'>
        <button className='counter_btn' onClick={(e) => decreaseItem(item, e)}>-</button>
        <span>{isExistItem.Qty}</span>
        <button className='counter_btn' onClick={(e) => increaseItem(item, e)}>+</button>
      </div>
    } else {
      return <>
        <div
          className={`price_div ${item.isDisabled && 'limit_over'} ${item.remainingToken <= 0 && 'limit_over'}`}>
          <div className='span_tag'>
            {
              item.itemType !== 3 ?
                <>
                  <span className='price_span'>{item.isDisabled ? '--' : item.price} $PUMP</span>
                  <span className='add_to_cart_span' onClick={(e) => handleAddCart(item, e)}>add to cart</span>
                </> :
                <span className='bid_span' onClick={(e) => handleBidOpen(item, e)}>bid</span>
            }
          </div>
        </div>
      </>
    }
  }

  //to increase product's quantity
  const increaseItem = (newItem, e) => {
    e.stopPropagation();
    const addQty = cart.map((item) => {
      if (item.tokenId === newItem.tokenId && item.Qty < Math.min(newItem.remainingToken, newItem.totalRemaining)) {
        item.Qty += 1
      }
      return item
    })
    setCart(addQty)
  };

  //to decrease product's quantity
  const decreaseItem = (newItem, e) => {
    e.stopPropagation()
    const removeQty = cart.map((item) => {
      if (item.tokenId === newItem.tokenId) {
        if (item.Qty > 1) {
          item.Qty -= 1
        }
      }
      return item
    })
    setCart(removeQty)
  };

  return (
    <>
      <div className='center_div'>
        <div className={`scroll_div ${isScrolling && 'scrolling'}`} onScroll={handleScroll}>
          {filteredCard?.map((item, i) => (
            <div key={i}
                 onMouseEnter={() => handleHovered(item)}
                 onMouseLeave={handleMouseLeave}
                 className={`card_display_div ${selectedCard?.tokenId === item.tokenId && 'selected_class'} `}
                 onClick={(e) => handleSelect(item, e)}>
              <img className={`${item.totalRemaining === 0 && 'sold_out'}`} src={item.image} alt='img'/>
              <div className='voucher_details'>
                <p className='voucher_heading'>{item.title}</p>
                <div className='card_bottom_div'>
                  <p className='id_category'><span>ID: </span> {item.tokenId}</p>
                  <p className='id_category'><span> Category:</span> {item.category}</p>
                </div>
              </div>
              <div className='conditional_div'>
                {item.totalRemaining !== 0 ?
                  <>
                    <HandleCheckInCart item={item}/>
                  </> :
                  <div className='span_tag'>
                    <span className='sold_out_span'>sold out</span>
                  </div>
                }
                {item.category === 'Whitelist' && <FaClipboardList onClick={(e) => {
                  handleShowWalletList(e, item)
                }} className='list_icon'/>}
                {item.category === 'Raffle' && isAdmin && <div className='list_icon_img' onClick={(e) => {
                  handleOpenRaffleModal(e, item)
                }}>
                </div>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default CenterColumn;