import React from "react";
import {useNavigate} from "react-router-dom";

const CenterColumn = ({
                        selectedCard,
                        setSelectedCard,
                        isScrolling,
                        handleMouseLeave,
                        handleScroll,
                        handleHovered,
                        filteredPurchaseCards
                      }) => {
  const navigate = useNavigate()

  const handleSelect = (item) => {
    if (selectedCard === item) {
      setSelectedCard()
    } else {
      setSelectedCard(item);
    }
  };

  return (
    <>
      <div className='center_div '>
        {filteredPurchaseCards.length ?
          <div className={`scroll_div ${isScrolling && 'scrolling'}`} onScroll={handleScroll}>
            {filteredPurchaseCards.map((item, i) => (
              <div key={i} className={`card_display_div ${selectedCard?.tokenId === item.tokenId && 'selected_class'}`}
                   onMouseLeave={handleMouseLeave}
                   onMouseEnter={() => handleHovered(item)}
                   onClick={() => handleSelect(item)}>
                <img src={item.image} alt='img'/>
                <div className='voucher_details'>
                  <p className='voucher_heading'>{item.title}</p>
                  <div className='card_bottom_div'>
                    <p className='id_category'><span>ID: </span> {item.tokenId}</p>
                    <p className='id_category'><span>Category: </span> {item.category}</p>
                  </div>
                </div>
              </div>
            ))}
          </div> :
          <div className='navigate_div'>No items to show. Visit the <span className='navigate_span'
                                                                          onClick={() => navigate('/shop')}>shop</span>.
          </div>
        }
      </div>
    </>
  )
}
export default CenterColumn;