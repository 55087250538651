import React from "react";
import {ImCross} from "react-icons/im";
import {RiCloseCircleFill} from "react-icons/ri";

const CartModal = ({
                     handleBuyProducts,
                     openBuyModal,
                     commaSeparateNumber,
                     totalPrice,
                     cart,
                     handleRemove,
                     flag,
                     modalRef,
                     onCloseClick,
                     balance
                   }) => {

  return (
    <>
      <div ref={modalRef} className={`popup_main_div ${openBuyModal && 'display'}`}>
        <div className='popup_div'>
          <span className='close_icon'><RiCloseCircleFill onClick={onCloseClick}/></span>
          <div className='popup_cart_div'>
            <div className='top_cart_div'>
              <h1>My Cart</h1>
              <p>{commaSeparateNumber(totalPrice)} $PUMP</p>
            </div>
            <div className='cart__items_div'>
              {cart?.map((cartItem, i) => (
                <div
                  className={`single_item ${cartItem.tokenId === flag && 'animate_class'} ${flag === 'deleteAll' && 'animate_class'}`}
                  key={i}>
                  <div className='remove' onClick={() => handleRemove(cartItem.tokenId)}><ImCross/></div>
                  <div className='details_div'>
                    <div className='img_div'>
                      <img src={cartItem.image} alt='img'/>
                    </div>
                    <div className='item_details'>
                      {cartItem?.title?.length > 24 ?
                        <p className='card_heading'>{cartItem?.title && cartItem?.title?.slice(0, 21) + "..."}</p> :
                        <p className='card_heading'>{cartItem.title}</p>
                      }
                      <p>ID: {cartItem.tokenId}</p>
                    </div>
                  </div>
                  <div className='price_qty_div'>
                    <h3>{cartItem.price} $PUMP</h3>
                    <p>Qty: {cartItem.Qty}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='popup_btn_div'>
            <button className='popup_button submit' onClick={handleBuyProducts}
                    disabled={cart.length === 0 || totalPrice > balance}>buy now
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default CartModal;