import React, {useEffect} from "react";
import {RiCloseCircleFill} from "react-icons/ri";

const SubmitModal = ({
                       submitModalOpen,
                       validWallets,
                       walletData,
                       setWalletData,
                       walletSubmitVoucher,
                       handleWalletSubmission,
                       handleGoBack,
                       modalRef,
                       handleGetAllWallet
                     }) => {

  useEffect(() => {
    const allInputs = [];
    if (walletSubmitVoucher) {
      [...Array(walletSubmitVoucher?.vouchers)]?.map((elem, i) => {
        allInputs.push({ walletId: (Date.now() * Math.random()).toString(), walletAddress: '', isInvalid: false })
      })
    }
    setWalletData(allInputs)
  }, [walletSubmitVoucher]);

  return (
    <>
      {walletSubmitVoucher !== undefined &&
      <div ref={modalRef} className={`popup_main_div ${submitModalOpen && 'display'}`}>
        <div className='popup_div popup_submit_details_div'>
          <span className='close_icon'><RiCloseCircleFill onClick={handleGoBack}/></span>
          <div className='voucher_details_div'>
            <p className='voucher_name'>{walletSubmitVoucher?.title}</p>
            <p className='text_heading'>Wallet submission</p>
          </div>
          <div className='middle_input_div'>
            <p className='enter_addresses'>Enter wallet addresses below</p>
            <div className='inputs_div'>
              {
                walletData?.map((elem, i) => (
                  <div key={i} className='wallet_input_div'>
                    <input spellCheck='false' id='wallet' value={elem.walletAddress} type='text'
                           autoComplete="off"
                           placeholder=" " onChange={(e) => handleGetAllWallet(elem, e)}/>
                    {elem.walletAddress.length !== 0 && elem.isInvalid &&
                    <p className='invalid_message'>Invalid address</p>}
                  </div>
                ))
              }
            </div>
          </div>
          <div className='popup_btn_div'>
            <button className='popup_button submit' disabled={validWallets.length === 0}
                    onClick={handleWalletSubmission}>Submit
            </button>
          </div>
        </div>
      </div>}
    </>
  )
}
export default SubmitModal;