import React from "react";

const Trait = ({
                 handleConfirmRoll,
                 selectedTrait,
                 setSelectedTrait,
                 selectedGuzzler,
                 setSelectedGuzzler,
                 allTraits,
                 allGuzzlers
               }) => {

  //for select and unselect guzzler
  const handleSelect = async (item) => {
    if (item === selectedGuzzler) {
      setSelectedGuzzler()
    } else {
      setSelectedGuzzler(item)
    }
  }

  //for select and unselect trait
  const handleSelectStone = (item) => {
    if (item?.traitName === selectedTrait?.traitName) {
      setSelectedTrait()
    } else {
      setSelectedTrait(item)
    }
  }

  return (
    <>
      <div className='center_div'>
        {allGuzzlers?.length > 0 ?
          <div className='trait_main_div program_main_div'>
            <div className='trait_details_div'>
              <div className="left_select_guzzler_div">
                <p className='choose_heading'>Choose a trait</p>
                <div className='box_id_div'>
                  <div className='scroll_id_div'>
                    {allTraits.map((item, i) => (
                      <div key={i} className='pump_div'>
                        <p onClick={() => handleSelectStone(item)}
                           className={`${item.quantity === 0 && 'disabled'} ${selectedTrait?.traitName === item?.traitName && 'selected_stone'}`}
                        >{item.traitName}</p>
                        <span>x{item.quantity}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="right_select_trait_div">
                <p className='select_p'>Choose a Guzzler</p>
                <div className='right_inner_div'>
                  <div className='right_scroll_div'>
                    {allGuzzlers.map((item, i) => (
                      <div className={`id_div ${selectedGuzzler === item && 'selected_id'}`} key={i}
                           onClick={() => handleSelect(item)}>
                        <span><p className='hash_tag'>#</p>{item}</span></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='roll_btn_div'>
              <button className='same_black_button'
                      disabled={selectedGuzzler === undefined || selectedTrait === null || selectedTrait === undefined}
                      onClick={handleConfirmRoll}>Roll
              </button>
            </div>
          </div> :
          <div className='navigate_div'>You don’t have any Guzzlers.</div>
        }
      </div>
    </>
  )
}
export default Trait;