import React from "react";
import InfoPanel from "../../../components/InfoPanel";
import FilterAndSort from "../../../components/FilterAndSort";

const LeftColumn = ({
                      allVouchers,
                      selectedFilter,
                      setSelectedFilter,
                      setFilteredCard,
                      allCategories,
                      hoveredCard
                    }) => {

  return (
    <>
      <div className='left_div'>
        <InfoPanel selectedCard={hoveredCard} />
        <FilterAndSort allVouchers={allVouchers}
                       selectedFilter={selectedFilter}
                       setSelectedFilter={setSelectedFilter}
                       setFilteredCard={setFilteredCard}
                       allCategories={allCategories}
        />
      </div>
    </>
  )
}

export default LeftColumn;