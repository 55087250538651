import React, {useEffect, useState} from "react";
import '../pages/shop/shop.scss'

const InfoPanel = ({ selectedCard }) => {
  const [time, setTime] = useState({ saleStartTime: '', auctionStartTime: '', auctionEndTime: '' });
  const routName = window.location.pathname
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    if (selectedCard !== undefined) {
      handleGetTime()
    }
  }, [selectedCard]);

  const handleGetTime = async () => {
    if (selectedCard?.itemType == '3') {
      const sTime = selectedCard?.auctionDetails.startTime * 1000
      const startDate = await new Date(sTime);
      const eTime = selectedCard?.auctionDetails.endTime * 1000
      const endDate = await new Date(eTime);
      setTime({ auctionStartTime: startDate.toLocaleString(), auctionEndTime: endDate.toLocaleString() })
    } else {
      const saleTime = selectedCard?.startTime * 1000
      const saleStartDate = await new Date(saleTime);
      setTime({ saleStartTime: saleStartDate.toLocaleString() })
    }
  }

  useEffect(() => {
    setIsHovered(true)
    setTimeout(() => {
      setIsHovered(false)
    }, 500)
  }, [selectedCard])

  return (
    <>
      <div className='card_info_div'>
        <div className='info_div'>
          <h3>info</h3>
          <p className='card_id'>ID: <span>{selectedCard ? selectedCard.tokenId : ""}</span></p>
        </div>
        <div className='info_scroll_div'>
          {selectedCard ?
            <div className={`info_inner_div ${isHovered && 'animate_data'}`}>
              {
                routName === '/shop' ?
                  <>
                    {
                      selectedCard?.itemType != '3' ?
                        <>
                          <div className='price_div'>
                            <p className='info_details'><span
                              className='info_heading'>{selectedCard?.itemType == '1' ? 'Price:' : 'Current Price'}</span> {selectedCard?.isDisabled ? '--' : selectedCard?.price} $PUMP
                            </p>
                          </div>
                          <div className='time_div'>
                            <p className='info_details'><span
                              className='info_heading'>Sale Starts: </span> {`${time?.saleStartTime?.slice(0, 6)}${time?.saleStartTime?.slice(8, 17)}`}
                            </p>
                          </div>
                          <div className='remain_div'>
                            <p className='info_details'><span
                              className='info_heading'>Available: </span> {Math.min(selectedCard?.remainingToken, selectedCard?.totalRemaining) >= 0 ? Math.min(selectedCard?.remainingToken, selectedCard?.totalRemaining) : 0}
                            </p>
                          </div>
                        </>
                        :
                        <>
                          <div className='time_div'>
                            <p className='info_details'><span
                              className='info_heading'>Auction Starts: </span> {`${time?.auctionStartTime?.slice(0, 6)}${time?.auctionStartTime?.slice(8, 17)}`}
                            </p>
                          </div>

                          <div className='time_div'>
                            <p className='info_details'><span
                              className='info_heading'>Auction Ends: </span> {`${time?.auctionEndTime?.slice(0, 6)}${time?.auctionEndTime?.slice(8, 17)}`}
                            </p>
                          </div>
                          <div className='remain_div'>
                            <p className='info_details'><span
                              className='info_heading'>Maximum Winners: </span>{selectedCard?.auctionDetails.maxWinnerAllowed}
                            </p>
                          </div>
                          <div className='price_div'>
                            <p className='info_details'><span
                              className='info_heading'>Minimum Bid: </span> {selectedCard?.currenBidAmount} $PUMP
                            </p>
                          </div>
                          <div className='remain_div'>
                            <p className='info_details'><span
                              className='info_heading'>Minimum Raise: </span>{Number(selectedCard?.auctionDetails.minBid)} $PUMP
                            </p>
                          </div>
                          <div className='remain_div'>
                            <p className='info_details'><span
                              className='info_heading'>Maximum Bids/Wallet: </span>{selectedCard?.auctionDetails.bidsAllowedPerUser}
                            </p>
                          </div>
                        </>
                    }
                    {
                      selectedCard.itemType == '2' &&
                      <>
                        <div className='d_price_div'>
                          <p className='info_details'><span
                            className='info_heading'>Starting Price: </span> {selectedCard?.dutchDetails.startingPrice} $PUMP
                          </p>
                        </div>
                        <div className='d_price_drop_div'>
                          <p className='info_details'><span
                            className='info_heading'>Price Drop: </span> {selectedCard?.dutchDetails.reducingPrice} $PUMP
                          </p>
                        </div>
                        <div className='d_price_drop_i_div'>
                          <p className='info_details'><span
                            className='info_heading'>Drop Interval: </span> {selectedCard?.dutchDetails.reducingTimeQuantum / 60 < 60 ?
                            `${selectedCard?.dutchDetails.reducingTimeQuantum / 60} min`
                            :
                            `${(selectedCard?.dutchDetails.reducingTimeQuantum / 60 / 60).toString().split('.')[0]} hr`
                          }
                          </p>
                        </div>
                        <div className='d_minimum_price_div'>
                          <p className='info_details'><span
                            className='info_heading'>Last Price: </span> {selectedCard?.dutchDetails.minimumPrice} $PUMP
                          </p>
                        </div>
                      </>
                    }
                  </> :
                  <div className='amount'>
                    <p className='info_details'><span
                      className='info_heading'>Amount: </span> {selectedCard?.vouchers}</p>
                  </div>
              }
              <div className='desc_div'>
                <p className='info_details'><span
                  className='info_heading'>Description: </span> {selectedCard?.description}</p>
              </div>
            </div> :
            <div className='info_empty_div'>
              <p>Select or hover over an item to know more about it.</p>
            </div>
          }
        </div>
      </div>
    </>
  )
}
export default InfoPanel;