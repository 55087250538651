import React from "react";
import {ImCross} from "react-icons/im";
import {AiFillPlusCircle} from "react-icons/ai";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';

const Program = ({
                   allVoucherCounter,
                   tiers,
                   handleGetValue,
                   handleDelete,
                   handleAdd,
                   txHashResponse,
                   inputs,
                   handleClaim,
                   sum,
                   isApiCalling,
                 }) => {

// for return value according txHash
  const HandleCheckResponse = ({ item }) => {
    if (item.data.length > 0) {
      const isExistItem = txHashResponse.find((elem) => elem.txHash === item.data)
      if (isExistItem) {
        if (isExistItem.isValid) {
          return <span className='price_tag'> {` ${isExistItem.returnAmount?.slice(0, 6)} ETH`}<span
            className='gas_fee'>GAS FEE </span></span>
        } else {
          if (isExistItem.isRepeat) {
            return <Tippy placement="top" arrow={true} content="Already claimed" visible={isExistItem.isRepeat}><span
              className='price_tag danger'> {` ${isExistItem.returnAmount?.slice(0, 6)} ETH`}<span
              className='gas_fee'>GAS FEE</span></span></Tippy>
          } else {
            return <Tippy placement="top" arrow={true} content="Invalid txn" visible={!isExistItem.isValid}><span
              className='price_tag danger'> {` ${isExistItem.returnAmount?.slice(0, 6)} ETH`}<span
              className='gas_fee'>GAS FEE</span></span></Tippy>
          }
        }
      } else {
        if (isApiCalling.inProcess && isApiCalling.inputId === item.id) {
          return <span className='price_tag'><span className='inner-span loading'>loading</span><span
            className='gas_fee'>GAS FEE</span></span>
        } else {
          return <span className='price_tag'>Invalid hash</span>
        }
      }
    } else {
      return <span className='price_tag'>0.0000 ETH<span className='gas_fee'>GAS FEE</span></span>
    }
  };


  return (
    <>
      <div className='center_div'>
        {allVoucherCounter > 0 ?
          <>
            <div className='program_main_div'>
              <p className='heading'>Available Vouchers</p>
              <div className='tier_option_div'>
                {
                  tiers.map((item, i) => (
                    <div key={i} className='tier_details_div'>
                      <p className={`${item.voucher === 0 && 'disabled'}`}>Tier {item.tier}</p>
                      <span>x{item.voucher}</span>
                    </div>
                  ))
                }
              </div>
              <p className='heading'>Enter txn hashes below</p>
              <div className='middle_main-div'>
                {tiers.map((element, i) => (
                  element.voucher > 0 &&
                  <div key={i} className='detail_tier_div'>
                    <p className='tier' id='tier_1'>Tier {element.tier}:</p>
                    <div className='input_price_div'>
                      <div className='input_scroll_div'>
                        {inputs.map((item, index) => (
                          item.tier === element.tier &&
                          <div key={index} className='input_div'>
                            <div className='form_div'>
                              <input id='tier_1' value={item.data} type='text' autoComplete="off"
                                     placeholder=" " onChange={(e) => handleGetValue(item, e)}/>
                              <button type="button" onClick={() => handleDelete(item)}><ImCross/></button>
                            </div>
                            <div className='price_div'>
                              <HandleCheckResponse item={item}/>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className='line_div'>
                        <div className={`plus_div ${element.voucher === element.counter && 'disabled'}`}
                             onClick={() => handleAdd(element)}>
                          <AiFillPlusCircle/>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='last_claim_btn_div'>
                <button className='same_black_button' disabled={sum === 0}
                        onClick={handleClaim}>claim
                </button>
                <div className='total_div'>
                  <span>Total</span>
                  {
                    sum !== 0 ?
                      <p className='amount'>{sum.toString().slice(0, 6)} ETH</p> :
                      <p className='amount'>0.0000 ETH</p>
                  }
                </div>
              </div>
            </div>
          </> :
          <div className='navigate_div'>You don’t have any gas-back vouchers.</div>
        }
      </div>
    </>
  )
}
export default Program;