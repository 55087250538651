import React, {useEffect, useState} from "react";
import './App.scss';
import Header from "./components/header/Header";
import {useMetaMask} from "metamask-react";
import Connect from "./components/connect/Connect";
import {Navigate, Route, Routes} from 'react-router-dom'
import Shop from "./pages/shop/Shop";
import Purchases from "./pages/purchases/Purchases";
import Loader from "./components/loader/Loader";
import config from "./config/test_config.json";
import {Slide, toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Utilities from "./pages/utilities/Utilities";
import {
  allBids,
  auctionDetails,
  balanceOf,
  getPurchasedItems,
  getTokenData, lowestBidAmount,
  providerHandler,
} from "./web3/contractInteraction";
import {checkDiscordUserName, getAllProducts, getConfigData} from "./utils/backendApi";
import '../src/pages/shop/shop.scss';
import TweetModal from "./components/tweetModal/TweetModal";

function App() {
  const { status, connect, account, chainId } = useMetaMask();
  const [loading, setLoading] = useState(false);
  const [purchasedVouchers, setPurchasedVouchers] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allVouchers, setAllVouchers] = useState([]);
  const [balance, setBalance] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [disCordMessage, setDisCordMessage] = useState();
  const [isScrolling, setIsScrolling] = useState(false);
  const [postTweet, setPostTweet] = useState({ transactionType: '', isSuccessful: false });
  const [isAdmin, setIsAdmin] = useState(false);
  const modalRef = React.useRef(null);

  useEffect(() => {
    accountSetup()
    getProducts()
  }, [chainId, account]);

  const accountSetup = async () => {
    const walletAddress = await providerHandler()
    const response = await checkDiscordUserName(walletAddress);
    if (!response) {
      setDisCordMessage({ isDiscord: false, message: 'Sorry, you do not have any holder roles on Discord' })
    } else {
      setDisCordMessage({ isDiscord: true, message: response.userName })
    }
    const configs = await getConfigData();
    const adminCheck = await configs?.adminWallets.some(wallet => wallet.toLowerCase() === walletAddress.toLowerCase())
    setIsAdmin(adminCheck)
  }

  const toastStyles = {
    textAlign: 'center',
    borderRadius: '4px',
    color: "rgba(255,255,255,0.8)",
    border: "1px solid rgb(132,136,132,0.5)",
    fontSize: "13px",
    fontFamily: "'Menlo', sans-serif",
    backgroundColor: "rgba(0,0,0,1)",
    backdropFilter: "blur(18px)",
    boxShadow: '0 0 18px rgb(0 0 0 / 80%)',
    width: "420px"
  }

  const toastOptions = {
    type: "success",
    autoClose: 5000,
    position: "bottom-left",
    closeButton: false,
    transition: Slide,
    hideProgressBar: true,
    closeOnClick: false,
    theme: "dark",
    icon: false,
    toastId: chainId,
    style: toastStyles
  }

  //for handle network changes
  const handlePolygonChainChange = async () => {
    const chainID = config.PolygonNetworkChainID;
    if (chainId !== null && chainId !== `0x${chainID}`) {
      setLoading(true)
      toast("Switch to Polygon Mainnet", {
        type: "warning",
        autoClose: false,
        position: "bottom-left",
        transition: Slide,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: false,
        theme: "dark",
        icon: false,
        toastId: chainID,
        style: toastStyles
      });

      try {
        await window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${chainID}` }],
          })
          .then(() => {
            toast.dismiss(chainID);
            getProducts()
            setLoading(false)
          })
      } catch (e) {
        if (e.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x89',
                chainName: 'Polygon mainnet',
                rpcUrls: ['https://polygon-rpc.com/'],
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18
                },
                blockExplorerUrls: ["https://polygonscan.com/"]
              },
            ],
          }).then(() => {
            accountSetup()
            getProducts()
            setLoading(false)
          })
        }
      }
    } else {
      toast.dismiss(chainID);
    }
  }

  //for get all products which are in Shop
  const getProducts = async () => {
    setLoading(true)
    if (status === 'connected' && account !== undefined) {
      let data = await getAllProducts();
      data = data.sort(function (a, b) {
        return a.tokenId - b.tokenId
      });
      if (data) {
        try {
          const response = await getTokenData(account, data)

          const a = response.allItems.filter(async (item) => {
            if (item.itemType === 3) {
              item.auctionDetails = await auctionDetails(item.auctionId)
              item.allBids = await allBids(item.auctionId)
              if (item.allBids[0].length >= item.auctionDetails.maxWinnerAllowed) {
                const lowestBid = await lowestBidAmount(item.auctionId)
                item.currenBidAmount = Number(lowestBid) + Number(item.auctionDetails.minBid)
              } else {
                item.currenBidAmount = Number(item.auctionDetails.startingBid)
              }
            }
            return item
          })

          setAllVouchers(a)
          setAllCategories(response.categories)
          const getVouchers = await getPurchasedItems(account, response.allItems);
          setPurchasedVouchers(getVouchers)
        } catch (error) {
          toast('Please reload the page.', toastOptions)
        }
      } else {
        toast('Please reload the page after sometime.', toastOptions);
      }
      try {
        const pumpBalance = await balanceOf(account)
        setBalance(pumpBalance)
      } catch (error) {
        console.log('Error in get pump balance :-', error)
        setBalance(0.00)
      }
    }
    setLoading(false)
  }

  //for get all purchased products
  const getPurchasedProducts = async () => {
    setLoading(true)
    if (allVouchers?.length !== 0 && status === 'connected' && account !== undefined) {
      const getVouchers = await getPurchasedItems(account, allVouchers);
      setPurchasedVouchers(getVouchers)
    }
    const pumpBalance = await balanceOf(account)
    setBalance(pumpBalance)
    setLoading(false)
  }

  const handleScroll = () => {
    setIsScrolling(true);
    setTimeout(() => {
      setIsScrolling(false)
    }, 1000)
  }

  const handleCloseTweetModal = () => {
    if (modalRef.current !== null) {
      modalRef.current.classList.add("out");
    }
    setTimeout(() => {
      setPostTweet(false)
    }, 400)
  }

  return (
    <>
      <div className="App">
        <ToastContainer/>
        <Loader loading={loading} loadingMessage={loadingMessage}/>
        <Header disCordMessage={disCordMessage} account={account} status={status} balance={balance}/>
        <TweetModal modalRef={modalRef} postTweet={postTweet} handleCloseTweetModal={handleCloseTweetModal}/>
        {
          status === 'initializing' ?
            <Loader loading={true}/> :
            status === 'connected' ?
              <div className='home_div'>
                <div className='glass_box'>
                  <Routes>
                    <Route path='/*' element={<Navigate to='/shop'/>}/>
                    <Route path='shop' element={<Shop setLoading={setLoading}
                                                      getProducts={getProducts}
                                                      purchasedVouchers={purchasedVouchers}
                                                      allVouchers={allVouchers}
                                                      account={account}
                                                      handlePolygonChainChange={handlePolygonChainChange}
                                                      toastOptions={toastOptions}
                                                      balance={balance}
                                                      allCategories={allCategories}
                                                      setLoadingMessage={setLoadingMessage}
                                                      handleScroll={handleScroll}
                                                      isScrolling={isScrolling}
                                                      chainId={chainId}
                                                      setPostTweet={setPostTweet}
                                                      isAdmin={isAdmin}
                    />}/>

                    <Route path='wallet' element={<Purchases purchasedVouchers={purchasedVouchers}
                                                             account={account}
                                                             handlePolygonChainChange={handlePolygonChainChange}
                                                             getPurchasedProducts={getPurchasedProducts}
                                                             allVouchers={allVouchers}
                                                             allCategories={allCategories}
                                                             chainId={chainId}
                                                             handleScroll={handleScroll}
                                                             isScrolling={isScrolling}
                    />}/>

                    <Route path='utilities' element={<Utilities account={account}
                                                                setLoading={setLoading}
                                                                getPurchasedProducts={getPurchasedProducts}
                                                                purchasedVouchers={purchasedVouchers}
                                                                handlePolygonChainChange={handlePolygonChainChange}
                                                                allVouchers={allVouchers}
                                                                toastOptions={toastOptions}
                                                                setLoadingMessage={setLoadingMessage}
                                                                chainId={chainId}
                                                                handleScroll={handleScroll}
                                                                setPostTweet={setPostTweet}
                                                                isScrolling={isScrolling}
                    />}/>
                  </Routes>
                </div>
                <div className='not_available_design'>
                  Please open this app on a desktop or a laptop.
                </div>
              </div> :
              <Connect account={account} status={status} connect={connect}/>
        }
      </div>
    </>
  );
}

export default App;