import React from "react";

const RollModal = ({ rollModalOpen, modalRef, selectedGuzzler, selectedTrait, handleReject, handleAccept }) => {

  return (
    <>
      <div ref={modalRef} className={`popup_main_div ${rollModalOpen && 'display'}`}>
        <div className='popup_div popup_roll_details_div'>
          <div className='roll_details_div'>
            <p className='id'>#{selectedGuzzler}</p>
            <p className='trait_name'>{selectedTrait?.traitName}</p>
            <div className='images_div'>
              <div className='left__div'>
                <p className='id'>Current</p>
                <p className='traitValue'>{selectedTrait?.oldValue} </p>
                <div className='img__div'><img src={selectedTrait?.oldImage}/></div>
              </div>
              <div className='right__div'>
                <p className='id'>Offered</p>
                <p className='traitValue'>{selectedTrait?.newValue}</p>
                <div className='img__div'><img src={selectedTrait?.newImage}/></div>
              </div>
            </div>
          </div>
          <div className='popup_btn_div'>
            <div className='reject_div'>
              <button className='popup_button reject' onClick={handleReject}>Reject</button>
            </div>
            <div className='accept_div'>
              <button className='popup_button accept' onClick={handleAccept}>Accept</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RollModal;