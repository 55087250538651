import React, {useEffect, useState} from "react";
import './purchases.scss';
import CenterColumn from "./layout/CenterColumn";
import RouteChange from "../../components/RouteChange";
import FilterAndSort from "../../components/FilterAndSort";
import InfoPanel from "../../components/InfoPanel";

const Purchases = ({
                     purchasedVouchers,
                     handlePolygonChainChange,
                     chainId,
                     account,
                     allVouchers,
                     handleScroll,
                     isScrolling,
                     allCategories,
                     getPurchasedProducts,
                   }) => {
  const [selectedCard, setSelectedCard] = useState()
  const [selectedFilter, setSelectedFilter] = useState('All Categories');
  const [filteredPurchaseCards, setFilteredPurchaseCards] = useState([]);
  const [hoveredCard, setHoveredCard] = useState();


  useEffect(() => {
    handlePolygonChainChange()
  }, [chainId, account]);

  useEffect(() => {
    //for sorting products
    getPurchasedProducts()
  }, [allVouchers]);

  const handleHovered=(item)=>{
    setHoveredCard(item)
  }

  const handleMouseLeave=()=>{
    setHoveredCard(selectedCard)
  }

  return (
    <>
      <div className='shop_main_div purchased_div'>
        <div className='left_div'>
          <InfoPanel selectedCard={hoveredCard}/>
          <FilterAndSort purchasedVouchers={purchasedVouchers}
                         setFilteredPurchaseCards={setFilteredPurchaseCards}
                         selectedFilter={selectedFilter}
                         setSelectedFilter={setSelectedFilter}
                         allCategories={allCategories}
          />
        </div>
        <CenterColumn selectedCard={selectedCard}
                      setSelectedCard={setSelectedCard}
                      filteredPurchaseCards={filteredPurchaseCards}
                      handleHovered={handleHovered}
                      handleScroll={handleScroll}
                      isScrolling={isScrolling}
                      handleMouseLeave={handleMouseLeave}
        />
        <div className='right_div'>
          <RouteChange/>
        </div>
      </div>
    </>
  )
}
export default Purchases;