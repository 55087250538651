import React from "react";
import {RiCloseCircleFill} from "react-icons/ri";

const WalletListModal = ({ walletListOpen, modalRef, handleCloseWalletList, walletListVoucher, walletListDetails }) => {

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    let file = new Blob([walletListDetails.join('\n')], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${walletListVoucher.tokenId}-${walletListVoucher.title}.txt`;
    document.body.appendChild(element);
    element.click();
  }

  return (
    <>
      {walletListVoucher !== undefined &&
      <div ref={modalRef} className={`popup_main_div ${walletListOpen && 'display'}`}>
        <div className='popup_div popup_walletList_div'>
          <span className='close_icon'><RiCloseCircleFill onClick={handleCloseWalletList}/></span>
          <div className='voucher_details_div'>
            <p className='voucher_name'>{walletListVoucher?.title}</p>
            <p className='text_heading'>{walletListVoucher?.category}</p>
          </div>
          <div className='wallet_list-div'>
            {walletListDetails.length === 0 ?
              <div className='empty_list_div'>No submission yet.</div>
              : walletListDetails.map((item, index) => (
                <div key={index} className='wallet_details_div'>
                  <p className='all_wallets'>{`${item.slice(0, 9)}...${item.slice(-9)}`}</p>
                </div>
              ))
            }

          </div>
          <div className='popup_btn_div'>
            <button className='popup_button cancel download' disabled={walletListDetails.length === 0}
                    onClick={downloadTxtFile}>Download
            </button>
          </div>
        </div>
      </div>}
    </>
  )
}
export default WalletListModal;