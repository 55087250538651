import React, {useEffect, useState} from "react";
import './utilities.scss';
import Program from "./layout/Program";
import Trait from "./layout/Trait";
import LeftColumn from "./layout/LeftColumn";
import RouteChange from "../../components/RouteChange";
import RollModal from "./layout/RollModal";
import gasJson from '../../config/gasPrice.json';
import {
  burnWhitelistToken,
  checkDiscordUserName,
  checkEligibility,
  claimTokens,
  getConfigData,
  getOldValue,
  getTokens,
  getTraitReRolls,
  getTransactionValue,
  setTraits
} from "../../utils/backendApi";
import {isHashUsed, Signature, Signer} from "../../web3/contractInteraction";
import {toast} from "react-toastify";
import configs from "../../config/test_config.json";
import mergeImages from 'merge-images';
import WhiteListing from "./layout/WhiteListing";
import SubmitModal from "./layout/SubmitModal";
import {ethers} from "ethers";


const Utilities = ({
                     handlePolygonChainChange,
                     chainId,
                     setPostTweet,
                     purchasedVouchers,
                     setLoading,
                     account,
                     getPurchasedProducts,
                     setLoadingMessage,
                     allVouchers,
                     handleScroll,
                     isScrolling,
                     toastOptions
                   }) => {
  const [selectedUtilityOption, setSelectedUtilityOption] = useState("gas_back");
  const [rollModalOpen, setRollModalOpen] = useState(false);
  const [selectedGuzzler, setSelectedGuzzler] = useState();
  const [selectedTrait, setSelectedTrait] = useState(null);
  const [allTraits, setAllTraits] = useState([]);
  const [allGuzzlers, setAllGuzzlers] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [allVoucherCounter, setAllVoucherCounter] = useState();
  const [tiers, setTiers] = useState([]);
  const [txHashResponse, setTxHashResponse] = useState([]);
  const [isApiCalling, setIsApiCalling] = useState({ inputId: '', inProcess: false });
  const [allConfigs, setAllConfigs] = useState();
  const [metaData, setMetaData] = useState({});
  const [isImageFetching, setIsImageFetching] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [sum, setSum] = useState(0);
  const [selectedCard, setSelectedCard] = useState();
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [walletSubmitVoucher, setWalletSubmitVoucher] = useState();
  const [walletData, setWalletData] = useState([]);
  const [validWallets, setValidWallets] = useState([]);
  const [hoveredCard, setHoveredCard] = useState();
  const modalRef5 = React.useRef(null);
  const modalRef6 = React.useRef(null);

  useEffect(() => {
    handlePolygonChainChange()
  }, [chainId]);

  useEffect(() => {
    getPurchasedProducts()
  }, [allVouchers]);

  useEffect(() => {
    setRollModalOpen(false)
    setSubmitModalOpen(false)
  }, [account])

  //initially show and set input fields in tier
  useEffect(() => {
    setTierCounters()
    getGuzzlers()
    handleGetConfigs()
  }, [purchasedVouchers]);

  useEffect(() => {
    handleGetMetaData()
  }, [selectedGuzzler]);

  useEffect(() => {
    getReroll()
  }, [allConfigs]);


  //for get sum of all txHash values
  useEffect(() => {
    let total = 0;
    txHashResponse.map((elem) => {
      if (elem.isValid) {
        total = Number(total) + Number(elem.returnAmount)
      }
    })
    setSum(total)
  }, [txHashResponse]);

  const handleGetConfigs = async () => {
    const configs = await getConfigData();
    setAllConfigs(configs)
  }

  const handleGetMetaData = async () => {
    if (selectedGuzzler !== undefined) {
      setIsFetching(true)
      setIsImageFetching(true)
      const getMetaData = await getOldValue(selectedGuzzler);
      setMetaData(getMetaData)
      setIsFetching(false)
    } else {
      setMetaData({})
    }
  }

  //for set purchased vouchers in tiers and set input fields accordingly
  const setTierCounters = async () => {
    const vouchers = [];
    for (let i = 0; i <= 5; i++) {
      purchasedVouchers.map((elem) => {
        if (elem.tokenId === i) {
          vouchers.push({ tier: i, voucher: elem.vouchers, counter: 1 })
        }
      })
      const isTierExist = vouchers.find(ele => ele.tier === i)
      if (!isTierExist) vouchers.push({ tier: i, voucher: 0, counter: 0 })
    }
    vouchers.sort(function (a, b) {
      return a.tier - b.tier
    });
    setTiers(vouchers)
    const voucher_inputs = []
    let voucher_sum = 0
    vouchers.length > 0 && vouchers.map((elem) => {
      voucher_sum += elem.voucher
      if (elem.voucher > 0) {
        voucher_inputs.push({ id: (Date.now() * Math.random()).toString(), tier: elem.tier, data: '' })
      }
    })
    setAllVoucherCounter(voucher_sum)
    setInputs(voucher_inputs)
  };

  //for add input fields in tier
  const handleAdd = (element) => {
    let counter = 0;
    inputs.map((elem) => {
      if (elem.tier === element.tier) {
        counter++
      }
    })
    setTiers(tiers.map((item) => {
      if (item.tier === element.tier) {
        item.counter = counter + 1
      }
      return item
    }))
    if (element.voucher > counter) {
      setInputs([...inputs, { id: Date.now().toString(), tier: element.tier, data: '' }]);
    }
  };

//for set onchange data into inputs array and show return value according txHash
  const handleGetValue = async (item, e) => {
    e.target.value = e.target.value.trim()
    let isSameValue = false;
    const res = inputs.map((input) => {
      const isRepeat = txHashResponse.find((elem) => elem.txHash === e.target.value)
      if (item.id === input.id && !isRepeat) {
        input.data = e.target.value
      }
      if (isRepeat) {
        isSameValue = true
      }
      return input
    })
    setInputs(res)
    if (isSameValue) {
      return false
    }
    let txHashes = [];
    setIsApiCalling({ inputId: item.id, inProcess: true })

    const response = await getTransactionValue(e.target.value)
    if (response !== false && response !== undefined && e.target.value.length !== 0) {
      const isLocked = txHashResponse.find(ele => ele.txHash === e.target.value)
      if (!isLocked) {
        const isUsed = await isHashUsed(e.target.value);
        const tierName = `tier${item.tier}`;
        if (allConfigs?.gasBackTokens[tierName].min < Number(response) && Number(response) < allConfigs?.gasBackTokens[tierName].max) {
          if (isUsed) {
            txHashes.push({
              txHash: e.target.value,
              ID: item.tier,
              isValid: false,
              isRepeat: true,
              returnAmount: response
            })
          } else {
            txHashes.push({
              txHash: e.target.value,
              ID: item.tier,
              isValid: true,
              isRepeat: false,
              returnAmount: response
            })
          }
        } else {
          txHashes.push({
            txHash: e.target.value,
            ID: item.tier,
            isValid: false,
            isRepeat: false,
            returnAmount: response
          })
        }
      }
      txHashes = txHashes.concat(txHashResponse).filter((elem) => inputs.find((j) => j.data === elem.txHash))
      setTxHashResponse(txHashes)
    } else {
      const res = txHashResponse.filter((elem) => inputs.find((j) => j.data === elem.txHash));
      setTxHashResponse(res)
    }
    setIsApiCalling({ inputId: '', inProcess: false })
  };

  //for remove input field from tier
  const handleDelete = (item) => {

    setTxHashResponse(txHashResponse.filter((i) => {
      return i.txHash !== item.data
    }));

    if (item.data.length !== 0) {
      setInputs(inputs.map((i) => {
        if (i.id === item.id) {
          i.data = ''
        }
        return i
      }));
    } else {
      setInputs(inputs.filter((i) => {
        return i.id !== item.id
      }));
      setTiers(tiers.map((elem) => {
        if (elem.tier === item.tier) {
          elem.counter = elem.counter - 1
        }
        return elem
      }))
    }
  };

  //for claim tokens
  const handleClaim = async () => {
    setLoading(true)
    const sign = await Signer(account, configs.redeemAddress, configs.domainName)
    if (sign.length > 0) {
      setLoadingMessage(true)
      let dataParams = {
        gasBack: [],
        userAddress: account,
        timestamp: sign[0],
        signature: sign[1]
      };
      txHashResponse.map((item) => {
        if (item.isValid) {
          const isAdded = dataParams.gasBack.find((elem) => elem.tier === `tier${item.ID}`)
          if (isAdded) {
            dataParams.gasBack.map((t) => {
              if (t.tier === isAdded.tier) {
                t.txHashList.push(item.txHash)
              }
            })
          } else {
            dataParams.gasBack.push({
              tier: `tier${item.ID}`,
              txHashList: [item.txHash]
            })
          }
        }
      });

      const res = await claimTokens(dataParams)
      if (res) {
        // toast("Your claim was successful.", toastOptions)
        setPostTweet({ isSuccessful: true, transactionType: 'refund', refundedAmount: sum.toString().slice(0, 6) })
      } else {
        toast("Something went wrong. Please try again.", toastOptions)
      }
    } else {
      toast("Signature request was denied.", toastOptions)
    }
    setLoadingMessage(false)
    setTxHashResponse([])
    setTierCounters()
    getPurchasedProducts()
  }

  // for change gas-back and trait re-roll option
  const handleUtilityChange = (tabName) => {
    setSelectedUtilityOption(tabName)
  }

  //for set all traits
  const getReroll = async () => {
    const allReRolls = [];
    allConfigs?.traitsReroll.map((item) => {
      const res = purchasedVouchers.find((elem) => elem.tokenId === item.tokenId)
      if (res !== undefined) {
        allReRolls.push({ ...item, quantity: res.vouchers })
      } else {
        allReRolls.push({ ...item, quantity: 0 })
      }
    });
    setAllTraits(allReRolls)
  };

  const getGuzzlers = async () => {
    const allTokens = await getTokens(account)
    setAllGuzzlers(allTokens?.tokens)
  }

  //for call Trait re-roll api on roll button click
  const handleConfirmRoll = async () => {
    if (selectedGuzzler !== undefined && selectedTrait !== undefined) {
      const checkIsValid = await checkEligibility(selectedGuzzler)
      if (!checkIsValid) {
        toast('Reroll is not possible for OG Gweiliens, 2 Traiters and 1/1s.', toastOptions)
        return
      }
      setLoading(true)
      const getSignature = await Signature()
      if (getSignature) {
        setLoadingMessage(true)
        const dataParameters = {
          tokenId: selectedGuzzler,
          traitName: selectedTrait?.traitName,
          userAddress: getSignature.address,
          message: getSignature.message,
          signature: getSignature.signature
        }

        const getValue = await metaData.attributes?.find((elem) => elem.trait_type === selectedTrait?.traitName)
        const res = await getTraitReRolls(dataParameters)
        if (getValue && res) {
          let gasPrice = '';
          await gasJson.map((token) => {
            if (token.tokenId == selectedGuzzler) {
              if (token.gas.toString().length === 1) {
                gasPrice = `00${token.gas}`
              } else if (token.gas.toString().length === 2) {
                gasPrice = `0${token.gas}`
              } else if (token.gas.toString().length === 3) {
                gasPrice = `0${token.gas}`
              }
            }
          });
          const splitGasPrice = await gasPrice.split('');
          const num1 = require(`../../assets/numbers/Column1/${splitGasPrice[0]}.png`);
          const num2 = require(`../../assets/numbers/Column2/${splitGasPrice[1]}.png`);
          const num3 = require(`../../assets/numbers/Column3/${splitGasPrice[2]}.png`);
          const numberImage = await mergeImages([num1, num2, num3]);
          const layers = {};
          for (let i = 0; i < allConfigs.traitsReroll.length; i++) {
            metaData.attributes.map((input) => {
              if (allConfigs.traitsReroll[i].traitName === input.trait_type && allConfigs.traitsReroll[i].traitName !== selectedTrait.traitName) {
                const url = require(`../../assets/traits/${input.trait_type}/${input.value}.png`);
                layers[input.trait_type] = url
              }
            })
          }
          layers[res.trait_type] = await require(`../../assets/traits/${res.trait_type}/${res.value}.png`)

          const b64 = await mergeImages([layers['Background'], layers['Ground'], layers['Roof Item'], layers['Sign'], layers['Machine'], layers['Machine Item'], layers['Gas Pump'], layers['Pump Head'], numberImage]);

          setSelectedTrait({
            ...selectedTrait,
            newImage: b64,
            newValue: res?.value,
            oldValue: getValue.value,
            oldImage: metaData?.image
          })
          setRollModalOpen(true)
        } else {
          toast("Something went wrong. Please try again.", toastOptions)
          getPurchasedProducts()
          setSelectedGuzzler()
          setSelectedTrait(null)
        }
        setLoadingMessage(false)
        setLoading(false)
      } else {
        toast("Signature request was denied.", toastOptions)
        setLoading(false)
      }
    }
  };

  //for call api on click accept button of modal
  const handleAccept = async () => {
    if (modalRef5.current !== null) {
      modalRef5.current.classList.add("out");
    }
    setTimeout(() => {
      setRollModalOpen(false)
      setLoading(true)
    }, 400)

    const getSignature = await Signature()
    if (getSignature) {
      setLoadingMessage(true)
      const dataParameters = {
        tokenId: selectedGuzzler,
        userAddress: getSignature.address,
        message: getSignature.message,
        signature: getSignature.signature
      }
      const res = await setTraits(dataParameters)
      if (res === 200) {
        // toast("The trait has been updated! Please refresh your Guzzler’s metadata.", toastOptions)
        setPostTweet({
          isSuccessful: true,
          transactionType: 'reroll',
          currentTrait: selectedTrait?.oldValue,
          offeredTrait: selectedTrait?.newValue,
          traitType: selectedTrait?.traitName,
          guzzlerId:selectedGuzzler
        })
      } else {
        toast("Something went wrong. Please try again.", toastOptions)
      }
    } else {
      toast("Signature request was denied.", toastOptions)
    }
    setLoadingMessage(false)
    setSelectedGuzzler()
    setSelectedTrait(null)
    getGuzzlers()
    getPurchasedProducts()
  }

  const onLoad = () => {
    setIsImageFetching(false)
  }

  //for close modal on click reject button of modal
  const handleReject = () => {
    if (modalRef5.current !== null) {
      modalRef5.current.classList.add("out");
    }
    setTimeout(() => {
      toast('Proposed trait update was rejected.', toastOptions)
      setRollModalOpen(false)
      setSelectedGuzzler()
      setSelectedTrait(null)
      getPurchasedProducts()
      getGuzzlers()
    }, 400)
  }

  const handleWalletSubmit = (e, voucher) => {
    e.stopPropagation();
    setSubmitModalOpen(true);
    setWalletSubmitVoucher(voucher)
  }

  const handleGetAllWallet = (voucher, e) => {
    let isSameValue = false;
    const res = walletData.map((input) => {
      const isRepeat = validWallets.find((elem) => elem === e.target.value);
      if (voucher.walletId === input.walletId && !isRepeat) {
        input.walletAddress = e.target.value
        const valid = ethers.utils.isAddress(e.target.value);
        input.isInvalid = !valid;
      }
      if (isRepeat) {
        isSameValue = true
      }
      return input
    })
    setWalletData(res)

    if (isSameValue) {
      return false
    }

    let allValidWallets = [];
    const valid = ethers.utils.isAddress(e.target.value);
    if (valid) {
      allValidWallets.push(e.target.value)
      allValidWallets = allValidWallets.concat(validWallets).filter((elem) => walletData.find((j) => j.walletAddress === elem));
      setValidWallets(allValidWallets)
    } else {
      const res = validWallets.filter((elem) => walletData.find((j) => j.walletAddress === elem));
      setValidWallets(res)
    }
  }

  const handleGoBack = () => {
    if (modalRef6.current !== null) {
      modalRef6.current.classList.add("out");
    }
    setTimeout(() => {
      setSubmitModalOpen(false);
      setWalletSubmitVoucher();
      setWalletData([]);
      setValidWallets([])
    }, 400)
  }

  const handleWalletSubmission = async () => {
    if (modalRef6.current !== null) {
      modalRef6.current.classList.add("out");
    }
    setTimeout(() => {
      setLoading(true);
      setSubmitModalOpen(false);
    }, 400)

    const response = await checkDiscordUserName(account);
    if (response) {
      const sign = await Signer(account, configs.WhitelistBurnerAddress, configs.wlName);
      if (sign.length > 0) {
        setLoadingMessage(true)
        const dataParameters = {
          tokenIds: [...Array(validWallets.length)].map(() => walletSubmitVoucher?.tokenId),
          whiteListAddresses: validWallets,
          userAddress: account,
          timestamp: sign[0],
          signature: sign[1]
        }
        const response = await burnWhitelistToken(dataParameters)
        if (response) {
          // toast("Submission successful!", toastOptions)
          setPostTweet({ isSuccessful: true, transactionType: 'whitelist', wlSpot: walletSubmitVoucher?.title })
        } else {
          toast("Something went wrong. Please try again.", toastOptions)
        }
        setLoadingMessage(false)
        getPurchasedProducts();
      } else {
        toast("Signature request was denied.", toastOptions)
      }

    } else {
      toast('Sorry, you do not have any holder roles on Discord', toastOptions)
    }
    setValidWallets([]);
    setWalletData([]);
    setSelectedCard()
    setWalletSubmitVoucher()
    setLoading(false)
  }

  const handleHovered = (item) => {
    setHoveredCard(item)
  }

  const handleMouseLeave = () => {
    setHoveredCard(selectedCard)
  }

  return (
    <>
      <div className='shop_main_div purchased_div claim_main_div'>
        <LeftColumn selectedUtilityOption={selectedUtilityOption}
                    metaData={metaData}
                    handleUtilityChange={handleUtilityChange}
                    hoveredCard={hoveredCard}
                    allConfigs={allConfigs}
                    isFetching={isFetching}
                    selectedGuzzler={selectedGuzzler}
        />
        {selectedUtilityOption === 'gas_back' &&
        <Program tiers={tiers}
                 inputs={inputs}
                 handleAdd={handleAdd}
                 handleClaim={handleClaim}
                 handleDelete={handleDelete}
                 handleGetValue={handleGetValue}
                 txHashResponse={txHashResponse}
                 allVoucherCounter={allVoucherCounter}
                 isApiCalling={isApiCalling}
                 sum={sum}
        />}
        {selectedUtilityOption === 're_roll' &&
        <Trait handleConfirmRoll={handleConfirmRoll}
               selectedTrait={selectedTrait}
               setSelectedTrait={setSelectedTrait}
               selectedGuzzler={selectedGuzzler}
               setSelectedGuzzler={setSelectedGuzzler}
               allTraits={allTraits}
               allGuzzlers={allGuzzlers}
        />}
        {selectedUtilityOption === 'whitelisting' &&
        <WhiteListing handleWalletSubmit={handleWalletSubmit}
                      setSelectedCard={setSelectedCard}
                      handleHovered={handleHovered}
                      handleMouseLeave={handleMouseLeave}
                      selectedCard={selectedCard}
                      handleScroll={handleScroll}
                      isScrolling={isScrolling}
                      purchasedVouchers={purchasedVouchers}
        />}

        <div className='right_div'>
          <RouteChange/>
          {selectedUtilityOption === 're_roll' &&
          <div className='current_img_display_div'>
            <div className='img_display'>
              {isFetching ?
                <div className="loading">loading</div>
                : selectedGuzzler !== undefined ?
                  <>
                    <div className={`loading ${!isImageFetching && 'hide'} `}>loading</div>
                    <img className={`${isImageFetching && 'hide'} `} src={metaData.image} onLoad={onLoad}/>
                  </>
                  :
                  <p>Select a Guzzler to view it's current image.</p>
              }
            </div>
          </div>
          }
        </div>
      </div>
      <RollModal rollModalOpen={rollModalOpen}
                 modalRef={modalRef5}
                 handleReject={handleReject}
                 selectedGuzzler={selectedGuzzler}
                 selectedTrait={selectedTrait}
                 handleAccept={handleAccept}
      />
      <SubmitModal setWalletData={setWalletData}
                   walletData={walletData}
                   handleGetAllWallet={handleGetAllWallet}
                   handleGoBack={handleGoBack}
                   walletSubmitVoucher={walletSubmitVoucher}
                   modalRef={modalRef6}
                   handleWalletSubmission={handleWalletSubmission}
                   validWallets={validWallets}
                   submitModalOpen={submitModalOpen}
      />
    </>
  )
}
export default Utilities;