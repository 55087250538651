import React from "react";
import {BsArrowRightShort} from "react-icons/bs";
import {useNavigate} from "react-router-dom";

const RouteChange = () => {
  const routName = window.location.pathname;
  const navigate = useNavigate();

  const goToShop = () => {
    navigate('/shop')
  }
  const goToUtilities = () => {
    navigate('/utilities')
  }
  const goToPurchased = () => {
    navigate('/wallet')
  }

  return (
    <>
      <div className='route_heading_div'>
        <h1
          className='route_heading'>{routName === '/shop' && 'shop' || routName === '/wallet' && 'wallet' || routName === '/utilities' && 'utilities'}</h1>
      </div>
      <div className='all_route_div'>
        {routName !== '/shop' && <div className='route_change_div'>
          <p>Shop</p>
          <div className='route_btn_div'>
            <button onClick={goToShop}><BsArrowRightShort className='arrow_icon'/></button>
          </div>
        </div>}
        {routName !== '/wallet' && <div className='route_change_div'>
          <p>Wallet</p>
          <div className='route_btn_div'>
            <button onClick={goToPurchased}><BsArrowRightShort className='arrow_icon'/></button>
          </div>
        </div>}
        {routName !== '/utilities' && <div className='route_change_div'>
          <p>Utilities</p>
          <div className='route_btn_div'>
            <button onClick={goToUtilities}><BsArrowRightShort className='arrow_icon'/></button>
          </div>
        </div>}
      </div>
    </>
  )
}
export default RouteChange;