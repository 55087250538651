import React from "react";
import {RiCloseCircleFill} from "react-icons/ri";
import {ethers} from "ethers";

const BidModal = ({
                    modalRef,
                    bidAmount,
                    bidModalOpen,
                    bidVoucher,
                    handleBidModalClose,
                    handlePlaceBid,
                    balance,
                    bidApprove,
                    handleApproveBidAmount,
                    handleGetBidAmount
                  }) => {

  return (
    <>
      <div ref={modalRef} className={`popup_main_div ${bidModalOpen && 'display'}`}>
        <div className='popup_div popup_bidding_div'>
          <span className='close_icon'><RiCloseCircleFill onClick={handleBidModalClose}/></span>
          <div className='heading_bid_div'>
            <p className='bid_title'>Place your bid</p>
            {bidVoucher?.auctionDetails.auctionDuration !== 'auction over' &&
            <p className='bid_end_date'>Auction ends: {bidVoucher?.auctionDetails.auctionDuration}</p>}
          </div>
          <div className='bid_voucher_details_div'>
            <div className='img_div'>
              <img src={bidVoucher?.image} alt='img'/>
            </div>
            <div className='item_details'>
              <p className='card_heading'>{bidVoucher?.title}</p>
              <p>ID: #{bidVoucher?.tokenId}</p>
            </div>
          </div>
          {
            bidVoucher?.auctionDetails.auctionDuration === 'auction over' ?
              <div className='auction_over'>
                <button>Auction Over</button>
              </div> :
              <>
                <div className='bid_input_div'>
                  <input spellCheck='false' id='amount' type='number'
                         onChange={(e) => handleGetBidAmount(e)}
                         autoComplete="off"
                         disabled={bidVoucher?.isLimitOver || !bidVoucher?.isBidAllowed}
                         placeholder={`Minimum bid: ${Number(bidVoucher?.currenBidAmount)}`}/>
                  <span className='bid_input_pump'>$pump</span>
                </div>
                <div className='popup_btn_div'>
                  {
                    bidApprove ? <button className='popup_button bid_btn' onClick={handleApproveBidAmount}
                      >approve
                      </button> :
                      <>
                        <button className='popup_button bid_btn' onClick={handlePlaceBid}
                                disabled={bidVoucher?.isLimitOver || Number(bidAmount) > Number(balance) || !bidVoucher?.isBidAllowed || Number(bidAmount) < Number(bidVoucher?.currenBidAmount)}>submit
                        </button>
                        {bidVoucher?.isLimitOver ?
                          <p className='warning_message'>Maximum gas voucher bid limit reached.</p> :
                          !bidVoucher?.isBidAllowed ?
                            <p className='warning_message'>Maximum bids limit reached.</p> :
                            Number(bidAmount) > Number(balance) ?
                              <p className='warning_message'>You don't have enough balance to bid.</p> :
                              Number(bidAmount) > 0 && Number(bidAmount) < Number(bidVoucher?.currenBidAmount) ?
                                <p className='warning_message'>Insufficient Bid.</p> : null
                        }
                      </>
                  }
                </div>
              </>
          }
          <div className='total_details-div'>
            <p className='total_bidders'>Total bidders: {bidVoucher?.totalBidders}</p>
            <p className='total_bids'>Total bids: {bidVoucher?.allBids[0].length}</p>
          </div>
          <p className='top-bids'>Currently winning</p>
          <div className='addresses_div'>
            {
              bidVoucher?.allBids[0]?.map((elem, i) => (
                <div key={i} className='total_details-div user_bid_list'>
                  <p>{elem.slice(0, 10) + "..." + elem.slice(-10)}</p>
                  <p>{Number(ethers.utils.formatEther(bidVoucher?.allBids[1][i])).toString().slice(0, 9)} $PUMP</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default BidModal;